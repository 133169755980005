import React, { useContext } from "react";
import navStyles from "./nav.module.css";
import { Link } from "gatsby";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import  { MenuContext } from '../../context/MenuContext';



export default function Nav({page}) {

  let about = 
    <Link to='/about' id="aboutme">
      about
    </Link>
  
  let poetry = 
    <Link to='/poetry' id="poetry">
      poetry/writing
    </Link>
  
  let editorial = 
    <Link to='/editorial' id="editorial">
      other work
    </Link>
  
  let resume = <Link to='/resume'>cv/resume</Link>

  switch(page) {
    case 'resume':
      resume = <Link to='/resume'><span id='selected'>cv/resume</span></Link>
      break
    case 'about':
      about = <Link to='/about'><span id='selected'>about</span></Link>
      break
    case 'editorial':
      editorial = <Link to='/editorial'><span id='selected'>other work</span></Link>
      break
    case 'poetry':
      poetry = <Link to='/poetry'><span id='selected'>poetry/writing</span></Link>
      break
  }
  const {open, setOpen} = useContext(MenuContext);

  let style = open ? navStyles.open : '';

  return (
    <div className={navStyles.navBar}>
      <ul className={navStyles.navLinks + ' ' + style}>
        <li>{about}</li>
        <li>{poetry}</li>
        <li>{editorial}</li>
        <li>{resume}</li>
      </ul>
      <div id={navStyles.icon} onClick={() => setOpen(!open)}>menu</div>
    </div>
  )
}
