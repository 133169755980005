import Nav from '../components/header/nav';
import React, {useState, createContext} from 'react';

const defaultState = {
  open: false,
  setOpen: () => {}

}
export const MenuContext = createContext(defaultState);

export const MenuProvider = ({ children }) => {
    const [open, setOpen] = useState(false);
  return (
    <MenuContext.Provider value={{open,setOpen}}>
      {children}
    </MenuContext.Provider>
  );
};