import './src/styles/global.css';

import React, { useState } from 'react';
import {MenuContext, MenuProvider} from './src/context/MenuContext';
const wrapRootElement = ({ element }) => {
  return (
      <MenuProvider>
        {element}
      </MenuProvider>
  );
};
export { wrapRootElement }